<template>
	<div class="trans-editor">
		<div class="topbar" style="">
			<p class="topbartext">
				<span v-if="isCopy">COPY </span>
				<span v-if="!isCopy && transob.id === 0">NEW </span>
				Record Details
			</p>
			<span style="cursor: pointer;" @click="$emit('closemodal')"><b-icon class="overlay-close-icon" icon="x"></b-icon></span>
		</div>
		<div id="txformhold" class="popform" style="display: block;">
			<div id="txForm">
				<div class="tx-form-top-section">
					<b-form-select class="midwidth" v-model="transob.accid" :options="accountsDropdownList"></b-form-select>
					<b-input-group class="datechoose">
						<b-form-input
								v-model="transob.datestr"
								type="text"
								placeholder="YYYY-MM-DD"
								autocomplete="off"
						></b-form-input>
						<b-input-group-append>
							<b-form-datepicker
									v-model="transob.datestr"
									button-only
									right
									locale="en-US"
							></b-form-datepicker>
						</b-input-group-append>
					</b-input-group>

					<b-form-select class="midwidth" v-model="transob.secticker" :options="securitiesDropdownList"></b-form-select>

					<b-form-select class="midwidth" style="width: 120px;" v-model="transob.itxtype">
						<option value="Buy">Buy</option>
						<option value="Sell">Sell</option>
						<option value="Div">Div</option>
						<option value="ShrIn">ShrIn</option>
						<option value="ShrOut">ShrOut</option>
					</b-form-select>

					<div class="amount"><b-form-input type="number" v-model="transob.brokerage"></b-form-input></div>

				</div>

				<div style="display: flex; padding: 30px 0 10px; margin-bottom: 0px; font-weight: bold;">
					<div class="category"><span style="padding: 0 0px;">N Shares</span></div>
					<div class="amount">Share Price</div>
					<div class="Lots">Lots</div>
				</div>

				<div style="margin-bottom: 10px; max-height: 55vh; overflow-y: auto;">
					<TransactionEditLineShareTrade v-for="(li, index) in transob.lineItems" :key="index"
							:datein="transob.datestr"
							:lineitem="li" :itxtype="transob.itxtype" v-on:update="li = $event"
							v-on:updatelines="recreatelines($event)" v-on:deleteline="removeLine(index)"
					></TransactionEditLineShareTrade>
				</div>

				<div style="padding: 5px 0; margin-bottom: 10px;">
					<span @click="newlineitem" style="font-size: 12px; color: #888; cursor:pointer;">New Line Item </span>
				</div>

				<div style="display: flex; justify-content: space-between; padding: 10px 0 10px">
					<div>
						<span class="primary-button" @click="saveTX();">Save</span>
						<span class="primary-button button-delete" @click="deleteTX();">Delete</span>
						<span class="primary-button button-grey" @click="cloneTX();">Copy To New</span>
					</div>
					<div style="font-weight: bold; font-size: 16px; line-height: 1.4;">
						Shares: {{totalShares}}<br/>
						Total: ${{numdisplay(transTotalAmount)}}
					</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import TransactionEditLineShareTrade from "@/components/TransactionEditLineShareTrade";

export default {
	name: "TransactionEditShareTrade",
	components: {TransactionEditLineShareTrade},
	props: {
		transid: Number,
	},
	data: function() {
		return {
			transob: {
				ixid: 0,
				accid: 0,
				datestr: "2021-05-31",
				secticker: '',
				itxtype: 'Buy',
				brokerage: 0,
				lineItems: [],
			},
			isCopy: false,
		}
	},
	computed: {
		totalShares() {
			var amt = 0;
			for(var li=0; li<this.transob.lineItems.length; li++) {
				amt = amt + parseFloat(this.transob.lineItems[li].nshr);
			}
			return amt;
		},
		transTotalAmount() {
			var amt = 0;
			for(var li=0; li<this.transob.lineItems.length; li++) {
				amt = amt + (parseFloat(this.transob.lineItems[li].nshr) * parseFloat(this.transob.lineItems[li].shrprice));
			}
			return amt + parseFloat(this.transob.brokerage);
		},
		accountsDropdownList() {
			let accs = [];
			for(var i=0; i<this.$store.state.accounts.length; i++) {
				var myacc = this.$store.state.accounts[i];
				if(myacc.type === "Investment") {
					accs.push({value: myacc.id, text: myacc.name});
				}
			}
			return accs;
		},
		securitiesDropdownList() {
			let secs = [];
			for(var i=0; i<this.$store.state.securities.length; i++) {
				var mysec = this.$store.state.securities[i];
				secs.push({value: mysec.ticker, text: mysec.ticker});
			}
			return secs;
		}
	},
	methods: {
		recreatelines(newlinevals) {
			this.transob.lineItems = [];
			for(var it=0; it<newlinevals.length; it++) {
				var myitem = newlinevals[it];
				var newitem = {
					ai: 0,
					nshr: myitem.nassigned * -1,
					shrprice: myitem.shrprice,
					secticker: myitem.secticker,
					buylotid: myitem.lotid,
				};
				this.transob.lineItems.push(newitem);
			}
		},
		cloneTX() {
			this.isCopy = true;
			this.transob.ixid = 0;
			for(var i=0; i<this.transob.lineItems.length; i++) {
				this.transob.lineItems[i].ai = 0;
			}
		},
		deleteTX() {
			let self = this;
			let form = {ixid: this.transid, accid: this.transob.accid};
			axios.post(
				"/post/delete-transaction-sharetrade.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.transactions) {
					self.$emit('updatetransactions', ret.transactions);
				}
				if(ret.accounts) {
					self.$store.commit('updateAccountsLists', ret.accounts);
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		saveTX() {
			let self = this;
			if(this.setAsReconciled === true) {
				this.transob.reconciled = 1;
			}
			let form = {tx: this.transob};
			axios.post(
				"/post/save-transaction-sharetrade.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.transactions) {
					self.$emit('updatetransactions', ret.transactions);
				}
				if(ret.accounts) {
					self.$store.commit('updateAccountsLists', ret.accounts);
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		removeLine(indexid) {
			this.transob.lineItems.splice(indexid, 1);
		},
		newlineitem() {
			this.transob.lineItems.push({
				ai: 0,
				nshr: 0,
				shrprice: 0,
				secticker: this.transob.secticker,
				buylotid: 0,
			});
		},
		getTransaction(txid) {
			if(txid > 0) {
				let self = this;
				let form = {txid: txid};
				axios.post(
					"/post/get-transaction-detail-share.php",
					JSON.stringify({
						data: form,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.transaction) {
						self.transob = ret.transaction;
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
			else {
				this.transob.accid = this.$store.state.activeAccountId;
				let today = new Date();
				var d = String(today.getDate()).padStart(2, '0');
				var m = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var y = today.getFullYear();
				this.transob.datestr = y+"-"+m+"-"+d;
				this.newlineitem();
			}
		},
	},
	mounted() {
		this.getTransaction(this.transid);
	},
	watch: {
		transid() {
			this.getTransaction(this.transid);
		},
	}
}
</script>

<style scoped>
.trans-editor {
	width: 1200px;
}
#txformhold {
	padding: 20px;
}
.tx-form-top-section {
	display: flex;
	justify-content: space-between;
}
.midwidth {
	width: 200px;
	margin-right: 10px;
}
.datechoose {
	width: 300px;
	margin-right: 10px;
}
.category {
	width: 400px;
}
.amount {
	width: 150px;
	margin-right: 10px;
}
</style>