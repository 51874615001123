<template>
	<div>
		<div id="navbar">
			<div>
				<router-link to="/dashboard">
					<span class="navbtn" :class="{navbtnactive : $route.path === '/dashboard'}">Dashboard</span>
				</router-link>
				<router-link to="/rules">
					<span class="navbtn" :class="{navbtnactive : $route.path === '/rules'}">Rules</span>
				</router-link>
				<router-link to="/investing">
					<span class="navbtn" :class="{navbtnactive : $route.path === '/investing'}">Investing</span>
				</router-link>
				<router-link to="/superannuation">
					<span class="navbtn" :class="{navbtnactive : $route.path === '/superannuation'}">Super</span>
				</router-link>
				<router-link to="/reports">
					<span class="navbtn" :class="{navbtnactive : $route.path === '/reports'}">Reports</span>
				</router-link>
				<router-link to="/net-worth-chart">
					<span class="navbtn" :class="{navbtnactive : $route.path === '/net-worth-chart'}">Net Worth</span>
				</router-link>
				<router-link to="/asset-allocation-chart">
					<span class="navbtn" :class="{navbtnactive : $route.path === '/asset-allocation-chart'}">Allocation</span>
				</router-link>
			</div>
			<div>
				<span class="kbtn kbtn-inline kbtn-in-nav" @click="fetchpocketsmith" v-bind:class="{'button-is-working':($store.state.pocketsmithsyncing === true)}">
					<span>Fetch PS</span>
					<WorkingButtonInsert v-if="$store.state.pocketsmithsyncing === true"></WorkingButtonInsert>
				</span>
				<span class="kbtn kbtn-inline kbtn-in-nav" @click="fetchstockprices" v-bind:class="{'button-is-working':($store.state.wsjstocksyncing === true)}">
					<span>Fetch Stocks</span>
					<WorkingButtonInsert v-if="$store.state.wsjstocksyncing === true"></WorkingButtonInsert>
				</span>
				<span class="kbtn kbtn-inline kbtn-in-nav" @click="runrules" v-bind:class="{'button-is-working':($store.state.rulesrunning === true)}">
					<span>Run Rules</span>
					<WorkingButtonInsert v-if="$store.state.rulesrunning === true"></WorkingButtonInsert>
				</span>
				<router-link to="/logout">
					<span class="kbtn kbtn-inline kbtn-in-nav">Logout</span>
				</router-link>
			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";

export default {
	name: "NavBar",
	components: {WorkingButtonInsert},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {
		fetchpocketsmith() {
			let self = this;
			this.$store.commit('setPocketsmithSyncStatus', true);
			axios.get(
				"/post/fetch-pocketsmith.php"
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				self.$store.commit('setPocketsmithSyncStatus', false);
				if(ret.accounts) {
					self.$store.commit('updateAccountsLists', ret.accounts);
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		runrules() {
			let self = this;
			this.$store.commit('setRunRulesStatus', true);
			axios.get(
				"/post/run-rules.php"
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				self.$store.commit('setRunRulesStatus', false);
				if(ret.accounts) {
					self.$store.commit('updateAccountsLists', ret.accounts);
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		fetchstockprices() {
			let self = this;
			this.$store.commit('setWsjStockSyncStatus', true);
			axios.get(
				"/post/fetch-stock-prices.php"
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				self.$store.commit('setWsjStockSyncStatus', false);
				if(ret.accounts) {
					self.$store.commit('updateAccountsLists', ret.accounts);
				}
				if(ret.securities) {
					self.$store.commit('updateSecuritiesList', ret.securities);
					self.$store.commit('updateWsjCount');
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
#navbar {
	position: fixed;
	top: 0;
	height: 50px;
	background: #0078c8;
	width: 100%;
	display: flex;
	align-content: center;
	padding-left: 20px;
	padding-right: 20px;
	align-items: center;
	justify-content: space-between;
	z-index: 20;
}
.navbtn {
	display: block;
	float: left;
	font-size: 14px;
	padding: 15px 10px 14px;
	margin-top: 7px;
	margin-right: 10px;
	border-bottom: none;
	color: #FFF;
	cursor: pointer;
	width: 100px;
	text-align: center;
	background-color: #1088D8;
	font-weight: 500;
}
.navbtn:hover {
	background-image: none;
	background-color: #2098E8;
}
.navbtnactive, .navbtnactive:hover {
	background-image: none;
	background-color: #FAFAFA;
	color: #222;
}

</style>