<template>
	<div style="width: 100%;">
		<NavBar></NavBar>

		<div class="internal-container" style="">

			<LeftAccountList></LeftAccountList>

			<div class="accountview-container">
				<div class="subnav-holder">
					<span class="subtab" @click="tabview='register'" :class="{subtabactive : tabview === 'register'}">
						<span v-if="activeAccount.type === 'Investment'">DRP</span>
						<span v-else>Register</span>
					</span>
					<span v-if="activeAccount.type === 'Investment'" class="subtab" @click="tabview='investtx'" :class="{subtabactive : tabview === 'investtx'}">
						<span>Trades</span>
					</span>
					<span class="subtab" @click="tabview='charts'" :class="{subtabactive : tabview === 'charts'}">
						<span>Overview</span>
					</span>
				</div>

				<div v-if="tabview === 'register'" class="register-hold">
					<RegisterTable v-if="$store.state.activeAccountId > 0"></RegisterTable>
				</div>

				<div v-if="tabview === 'investtx'" class="register-hold">
					<RegisterShareTradesTable v-if="$store.state.activeAccountId > 0"></RegisterShareTradesTable>
				</div>

				<div v-if="tabview === 'charts'" class="register-hold">
					<AccountOverview :account="activeAccount"></AccountOverview>
				</div>

			</div>
		</div>
	</div>

</template>

<script>
import LeftAccountList from "@/components/views/LeftAccountList";
import RegisterTable from "@/components/RegisterTable";
import RegisterShareTradesTable from "@/components/RegisterShareTradesTable";
import NavBar from "@/components/NavBar";
import AccountOverview from "@/components/AccountOverview";

export default {
	name: "AccountView",
	components: {
		AccountOverview,
		NavBar,
		RegisterShareTradesTable,
		RegisterTable,
		LeftAccountList,
	},
	data: function () {
		return {
			tabview: 'register',
		}
	},
	computed: {
		activeAccountId() {
			return this.$store.state.activeAccountId;
		},
		activeAccount() {
			return this.$store.getters.activeAccount;
		},
	},
	methods: {

	},
	watch: {
		activeAccountId() {
			if(this.activeAccount.type == "Investment") {
				this.tabview = 'investtx';
			}
			else {
				this.tabview = 'register';
			}
		}
	},
	mounted() {
		let urlparams = this.$route.params; //params not query
		if(urlparams.accid !== undefined) {
			this.$store.commit('setActiveAccount', parseInt(urlparams.accid));
		}
	},
}
</script>

<style scoped>
.accountview-container {
	width: calc(100% - 280px);
	left: 280px;
	position: relative;
}
.register-hold {
	width: 1200px;
	position: relative;
	margin: 40px auto 20px;
	padding: 0;
}
</style>