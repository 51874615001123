<template>
	<div style="position: relative;">

		<div class='register-table'>
			<div class="register-above">
				<div class="searchbox-hold">
					<input type="text" v-model="searchtext" class="searchbox" placeholder="Search"/>
				</div>
				<div>
					<span style="cursor:pointer; padding-right: 10px; margin-left: 10px; color: #fff; font-size: 12px;" @click="addNewTx">Add Manual Transaction</span>
				</div>
			</div>

			<div class='headrow'>
				<div class='mids'>Date</div>
				<div class='mids'>Security</div>
				<div class='mids'>Trade Type</div>
				<div class='rt mids'>Brokerage</div>
				<div class='rt mids'>N Shares</div>
				<div class='rt mids'>Share Price</div>
				<div class="rt mids">Cash Change</div>
				<div class="rt mids">Matched</div>
			</div>

			<div class="table-rows">
				<div v-for="t in shownTransactions" :key="t.ixid" class='trow'
					@click="editTransaction(t.ixid)" :class="t.itxtype"
				>
					<div class='mids'>{{t.datestr}}</div>
					<div class='mids'>{{t.secticker}}</div>
					<div class='mids'>{{t.itxtype}}</div>
					<div class='rt mids'>{{numdisplay(t.brokerage)}}</div>
					<div class='rt mids'>{{t.totalNShr}}</div>
					<div class='rt mids'>{{numdisplay(t.avgShrPrice)}}</div>
					<div class='rt mids'>{{numdisplay(t.totalAmount)}}</div>
					<div class="rt mids">
						<span v-if="t.itxtype === 'Sell' && t.sellLotsMatched">M</span>
					</div>
				</div>
			</div>
		</div>
		<div v-if="transactions.length > shownTransactions.length" @click="itemsShown = 0" class="show-more-div" style="width: 1120px;">
			<span class="kbtn">Show All</span>
		</div>

		<div class="overlay" v-if="txEditShown">
			<TransactionEditShareTrade :transid="activeTransId"
						v-on:closemodal="txEditShown = false"
						v-on:updatetransactions="finishEditTransaction($event)"
			></TransactionEditShareTrade>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import TransactionEditShareTrade from "@/components/TransactionEditShareTrade";

export default {
	name: "RegisterShareTradesTable",
	components: {
		TransactionEditShareTrade,
	},
	props: {


	},
	data: function () {
		return {
			transactions: [],
			itemsShown: 200,
			txEditShown: false,
			activeTransId: 0,
			searchtext: '',
		}
	},
	computed: {
		activeAccountId() {
			return this.$store.state.activeAccountId;
		},
		activeAccount() {
			return this.$store.getters.activeAccount;
		},
		pocketsmithsyncing() {
			return this.$store.state.pocketsmithsyncing;
		},
		shownTransactions() {
			if (this.searchtext.length > 2) {
				var src = this.searchtext.toLowerCase();
				return this.transactions.filter(item =>
					item.secticker.toLowerCase().includes(src) || item.itxtype.toLowerCase().includes(src)
				);
			}
			if (this.itemsShown === 0) return this.transactions; //return all if limit not set
			else return this.transactions.slice(0, this.itemsShown); //just the first n(200) transactions
		},
	},
	methods: {
		addNewTx() {
			this.activeTransId = 0;
			this.txEditShown = true;
		},
		finishEditTransaction(transitems) {
			this.txEditShown = false;
			this.activeTransId = 0;
			this.transactions = transitems;
		},
		editTransaction(txid) {
			this.activeTransId = txid;
			this.txEditShown = true;
		},
		getTransactions() {
			let self = this;
			let form = {accid: this.$store.state.activeAccountId};
			axios.post(
				"/post/get-transactions-shares.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.accounts) {
					self.$store.commit('updateAccountsLists', ret.accounts);
				}
				if (ret.securities) {
					self.$store.commit('updateSecuritiesList', ret.securities);
				}
				if (ret.transactions) {
					self.transactions = ret.transactions;
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		activeAccountId() {
			this.itemsShown = 200; //reset this on change of account
			this.getTransactions();
		},
		pocketsmithsyncing() {
			if (this.pocketsmithsyncing === false) {
				this.getTransactions();
			}
		},
	},
	mounted() {
		if (this.activeAccountId > 0) {
			this.getTransactions();
		}
	}
}
</script>

<style scoped>

</style>