<template>
	<div style="width: 100%;" v-if="$store.state.user.id > 0">
		<NavBar></NavBar>
		<div class="internal-container">

			<div class="dashboard-container">
				<div v-if="nwseries.length >0" class="account-tile-hold" style="position: absolute; width: 100%;">
					<div style="margin-top: 30px; padding: 10px 50px; width: 100%; position: relative;">
						<div>
							<input type="checkbox" v-model="showAsPct" id="ssin" /><label for="ssin" style="margin-left: 5px;">Show as Pct</label>
							<input style="margin-left: 20px;" type="checkbox" v-model="showSuper" id="ssin2" /><label for="ssin2" style="margin-left: 5px;">Super</label>
							<input style="margin-left: 20px;" type="checkbox" v-model="showNonSuper" id="ssin3" /><label for="ssin3" style="margin-left: 5px;">Non Super</label><br/><br/>
						</div>
						<ChartHolderDash :charttitle="'Asset Allocations'" :height="600" :addclasses="'chartholderdash'">
							<div v-if="lookupdone" class="chart-container" style="width: 100%; ">
								<apexchart width="99%" height="100%" type="area" :options="nwoptions" :series="nwseries"></apexchart>
							</div>
							<ChartLoaderInsert v-if="lookupdone === false"></ChartLoaderInsert>
						</ChartHolderDash>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
export default {
	name: "Allocation",
	components: {
		ChartLoaderInsert,
		ChartHolderDash,
		NavBar
	},
	props: {},
	data: function () {
		return {
			lookupdone: false,
			enddate: '',
			showAsPct: false,
			showSuper: true,
			showNonSuper: true,

			nwoptions: {
				chart: {
					id: 'allocation',
					toolbar: {
						show: true,
					},
					stacked: true,
				},
				xaxis: {
					categories: [],
					labels: {
						show: true,
					},
					axisTicks: {
						show: true,
					},
					type: 'datetime',
				},
				tooltip: {
					x: {
						format: 'dd MMM yyyy'
					}
				},
				toolbar: {
					autoSelected: 'zoom',
				},
				yaxis: {
					decimalsInFloat: 3,
					labels: {
						formatter: function(val) {
							try {
								if(this.showAsPct === false) {
									return "$" + val.toLocaleString('en-us');
								}
								else {
									return Math.round(val * 100) + "%";
								}
							}
							// eslint-disable-next-line no-empty
							catch(e) {  }
						}.bind(this),
					},
				},
				stroke: {
					width: 2.5,
					curve: 'straight',
					// colors: ['#0078c8'],
				},
				fill: {
					shadeIntensity: 1,
					// colors: ['#0078c8'],
					opacity: 0.1,
					gradient: {
						opacityFrom: 0.7,
						opacityTo: 0.1,
					},
				},
				dataLabels: {
					enabled: false,
				},
				markers: {
					size: 0,
				},
			},
			nwseries: [],
		}
	},
	computed: {
		showAsStacked() {
			if(this.showAsPct === true) return false;
			return true;
		},
	},
	methods: {
		doChartLookup() {
			this.lookupdone = false;
			let self = this;
			let form = {
				getter: 'dashnetworth',
				showAsPct: this.showAsPct,
				showSuper: this.showSuper,
				showNonSuper: this.showNonSuper,
			};
			axios.post(
				"/post/get-allocation-runbal.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(self.showAsPct === true) self.nwoptions.chart.stacked = false;
				else self.nwoptions.chart.stacked = true;
				self.nwseries = ret.nwchart.series;
				self.lookupdone = true;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		showSuper() {
			this.doChartLookup();
		},
		showNonSuper() {
			this.doChartLookup();
		},
		showAsPct() {
			this.doChartLookup();
		}
	},
	mounted() {
		this.doChartLookup();
		document.title = "Fintrac - Net Worth";
	}
}
</script>

<style scoped>

</style>