<template>
	<div style="display: flex; padding: 10px 0 0px; font-weight: bold;">
		<div class="category">
			<Autocompleter :searchlist="$store.state.subcats" :incomingval="mylineitem.subCatId"
						idfldname="id" textfldname="fullname" v-on:update="updateSubcatObject($event)"
			></Autocompleter>
		</div>
		<div class="memo"><b-form-input type="text" v-model="mylineitem.memo"></b-form-input></div>
		<div class="amount"><b-form-input type="number" v-model="mylineitem.amount"></b-form-input></div>
		<div class="investflds"><b-form-input v-if="mylineitem.subcatObj.id === 104" type="text" v-model="mylineitem.secticker"></b-form-input></div>
		<div class="investflds"><b-form-input v-if="mylineitem.subcatObj.id === 104" type="number" v-model="mylineitem.frkcrd"></b-form-input></div>
		<div style="display: flex; align-items: center; padding: 5px 10px;">
			<span @click="$emit('deleteline')" style="cursor: pointer;">X</span>
		</div>
	</div>
</template>

<script>

import Autocompleter from "@/components/Autocompleter";
export default {
	name: "TransactionEditLine",
	components: {
		Autocompleter,
	},
	props: {
		lineitem: Object,
	},
	data: function() {
		return {
			mylineitem: {
				memo: '',
				amount: 0,
				subcatObj: {id: 0, fullname: ''},
				subCatId: 0,
				itemId: 0,
				secticker: '',
				frkcrd: 0,
			},
		}
	},
	computed: {

	},
	methods: {
		updateSubcatObject(ob) {
			this.mylineitem.subCatId = ob.id;
			this.mylineitem.subcatObj = ob;
		},
		prepareItem() {
			this.mylineitem = this.lineitem;
		},
	},
	mounted() {
		this.prepareItem();
	},
	watch: {
		lineitem() {
			this.prepareItem();
		},
		mylineitem() {
			this.$emit('update', this.mylineitem);
		},
	}
}
</script>

<style scoped>
.category {
	width: 360px;
}
.memo {
	width: 400px;
	margin-right: 10px;
}
.amount {
	width: 150px;
	margin-right: 10px;
}
.amount input {
	text-align: right;
}
.investflds {
	width: 90px;
	margin-right: 10px;
}
</style>