<template>
	<div id="app">
		<KitlAlertTop :obj="$store.state.kalert"></KitlAlertTop>
		<router-view></router-view>
	</div>
</template>

<script>
import axios from "axios";
import KitlAlertTop from "@/components/KitlAlertTop";

export default {
	name: 'App',
	components: {KitlAlertTop},
	data: function () {
		return {
			requestedRoute: '',
		}
	},
	methods: {
		sesscheck() {
			let self = this;
			axios.get(
				"/post/firstrun.php"
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.needsession && ret.needsession === 1) {
					if(self.$route.path !== '/login') {
						self.$router.push("/login");
					}
					return false;
				}
				else {
					if(self.$route.path === '/') {
						self.$router.push('/dashboard');
					}
				}
				if(ret.user) {
					self.$store.commit('setUser', ret.user);
				}
				if(ret.lists) {
					self.updateMainLists(ret.lists);
				}
				self.$store.commit('setSesscheckComplete', true);
			}).catch(function (error) {
				console.log(error);
			});
		},
		updateMainLists(listsarray) {
			if(listsarray.accounts) {
				this.$store.commit('updateAccountsLists', listsarray.accounts);
			}
			if(listsarray.payees) {
				this.$store.commit('updatePayeesList', listsarray.payees);
			}
			if(listsarray.subcats) {
				this.$store.commit('updateSubcatsList', listsarray.subcats);
			}
			if(listsarray.securities) {
				this.$store.commit('updateSecuritiesList', listsarray.securities);
			}
		},

	},
	mounted() {
		this.requestedRoute = this.$router.currentRoute.path;
		this.sesscheck();
	},
	created() {
		//handle incoming GET parameters
		// let urlparams = this.$route.query;
		// if(urlparams.e !== undefined) {
		// 	this.$store.commit('setRegistrationEmail', urlparams.e);
		// }
	},
	watch: {
		$route() {
			//force close alert on page change
			//this.$store.commit('closeKalert');
		}
	},
}
</script>

<style>
#app {
	font-family: 'Roboto', Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 50px;
	min-height: calc(100vh - 50px);
	display: flex;
	position: relative;
}
</style>
