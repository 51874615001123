<template>
	<div>

		<div style="margin-top: 100px; position: relative;">
			<ChartHolderDash :charttitle="'Account Balance'" :height="600" :addclasses="'chartholderdash'">
				<div v-if="lookupdone" class="chart-container" style="width: 100%; ">
					<apexchart width="99%" height="100%" type="area" :options="nwoptions" :series="nwseries"></apexchart>
				</div>
				<ChartLoaderInsert v-if="lookupdone === false"></ChartLoaderInsert>
			</ChartHolderDash>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";

export default {
	name: "AccountOverview",
	components: {
		ChartLoaderInsert,
		ChartHolderDash,
	},
	props: {
		account: Object,
	},
	data: function () {
		return {
			lookupdone: false,

			nwoptions: {
				chart: {
					id: 'networth',
					toolbar: {
						show: true,
					},
				},
				xaxis: {
					categories: [],
					labels: {
						show: true,
					},
					axisTicks: {
						show: true,
					},
					type: 'datetime',
				},
				tooltip: {
					x: {
						format: 'dd MMM yyyy'
					}
				},
				toolbar: {
					autoSelected: 'zoom',
				},
				yaxis: {
					decimalsInFloat: 3,
					labels: {
						formatter: function(val) {
							return "$" + val.toLocaleString('en-us');
						},
					},
				},
				stroke: {
					width: 2.5,
					curve: 'straight',
					colors: ['#0078c8'],
				},
				fill: {
					shadeIntensity: 1,
					colors: ['#0078c8'],
					opacity: 0.1,
					gradient: {
						opacityFrom: 0.7,
						opacityTo: 0.1,
					},
				},
				dataLabels: {
					enabled: false,
				},
				markers: {
					size: 0,
				},
			},
			nwseries: [],
		}
	},
	computed: {

	},
	methods: {
		doDashboardLookups() {
			let self = this;
			let form = {
				getter: 'dashnetworth',
				accid: this.account.id
			};
			axios.post(
				"/post/get-account-dashboard.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				self.nwoptions.xaxis.categories = ret.nwchart.xaxis_categories;
				self.nwseries = ret.nwchart.series;
				self.lookupdone = true;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {

	},
	mounted() {
		this.doDashboardLookups();
	}
}
</script>

<style scoped>

</style>