<template>
	<div style="width: 100%;" v-if="$store.state.user.id > 0">
		<NavBar></NavBar>
		<div class="internal-container">

			<div class="dashboard-container">

				<div class="account-tile-hold" style="margin-top: 0;">
					<div style="padding: 20px;">
						<p class="heading">Reports</p>
						<div style="width: 1000px; justify-content: space-between; display: flex;">
							<b-select v-model="reporttype" style="width: 300px;">
								<option value="balance-sheet">Balance Sheet</option>
								<option value="realised-capital-gains">Realised Capital Gains</option>
								<option value="unrealised-capital-gains">Unrealised Capital Gains</option>
							</b-select>

							<b-input-group class="datechoose" style="width: 300px;">
								<b-form-input
										v-model="enddate"
										type="text"
										placeholder="YYYY-MM-DD"
										autocomplete="off"
								></b-form-input>
								<b-input-group-append>
									<b-form-datepicker
											v-model="enddate"
											button-only
											right
											locale="en-US"
									></b-form-datepicker>
								</b-input-group-append>
							</b-input-group>

							<span class="kbtn kbtn-inline" @click="doSummaryLookups">Fetch</span>
						</div>

						<div class="report-holder">
							<div v-if="totals.super && reporttype === 'balance-sheet'">
								<div>
									<table class="report-table">
										<tr v-for="acc in nonsuperaccts" :key="acc.id">
											<td class="report-td-name" :title="'ID '+acc.id">{{acc.name}}</td>
											<td class="rt">{{numdisplay(acc.lastbal)}}</td>
										</tr>
										<tr>
											<td class="bold">Total Personal Cash</td>
											<td class="bold rt">{{numdisplay(totals.nonsupercash)}}</td>
										</tr>
										<tr>
											<td class="bold">Total Investible</td>
											<td class="bold rt">{{numdisplay(totals.nonsuperinvestible)}}</td>
										</tr>
										<tr>
											<td class="bold">Invested (excl WC)</td>
											<td class="bold rt">{{numdisplay(totals.nonsuperinvested)}}</td>
										</tr>
										<tr>
											<td class="bold">Total excl WC</td>
											<td class="bold rt">{{numdisplay(totals.nonsuperexclwc)}}</td>
										</tr>
										<tr>
											<td class="bold">Grand Total Non-Super</td>
											<td class="bold rt">{{numdisplay(totals.nonsupergrandtotal)}}</td>
										</tr>
										<tr><td>-----</td></tr>
										<tr v-for="acc in superaccts" :key="acc.id">
											<td>{{acc.name}} {{acc.id}}</td>
											<td class="rt">{{numdisplay(acc.lastbal)}}</td>
										</tr>
										<tr>
											<td class="bold">Total</td>
											<td class="bold rt">{{numdisplay(totals.super)}}</td>
										</tr>
										<tr><td>-----</td></tr>
										<tr>
											<td class="bold">Grand Total excl FT Loan</td>
											<td class="bold rt">{{numdisplay(totals.grandtotalexclFTloan)}}</td>
										</tr>
										<tr>
											<td class="bold">Grand Total excl House & FT Loan</td>
											<td class="bold rt">{{numdisplay(totals.grandtotalexclhouse)}}</td>
										</tr>
										<tr>
											<td class="bold">Grand Total</td>
											<td class="bold rt">{{numdisplay(totals.grandtotal)}}</td>
										</tr>
									</table>
								</div>
							</div>

							<div v-if="rcglotslist.length > 0 && (reporttype === 'realised-capital-gains' || reporttype === 'unrealised-capital-gains')">
								<div v-html="rcglotslist">

								</div>
							</div>

						</div>



					</div>
				</div>


			</div>
		</div>

	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";
export default {
	name: "Reports",
	components: {
		NavBar
	},
	props: {},
	data: function () {
		return {
			lookupdone: false,
			reporttype: 'balance-sheet',
			enddate: "2021-05-31",
			startdate: "2021-05-31",
			superaccts: [],
			nonsuperaccts: [],
			totals: {},

			rcglotslist: '',
		}
	},
	computed: {

	},
	methods: {
		doSummaryLookups() {
			let self = this;
			let form = {
				type: 'report',
				startdate: this.startdate,
				enddate: this.enddate,
			};
			axios.post(
				"/post/reports/"+this.reporttype+".php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.super) {
					self.superaccts = ret.super;
				}
				if (ret.nonsuper) {
					self.nonsuperaccts = ret.nonsuper;
				}
				if (ret.totals) {
					self.totals = ret.totals;
				}
				if (ret.lotcglist) {
					self.rcglotslist = ret.lotcglist;
				}
				self.lookupdone = true;
			}).catch(function (error) {
				console.log(error);
			});
		},

	},
	watch: {

	},
	mounted() {
		document.title = "Fintrac - Reports";
		this.startdate = this.dateobToYmd(new Date());
		this.enddate = this.dateobToYmd(new Date());
	}
}
</script>

<style scoped>
.report-holder {
	background-color: #FFF;
	padding: 30px;
	margin: 30px 0;
}
.report-table tr {
	border-bottom: 1px solid #EEE;
}
.report-table td {
	padding: 4px 5px 3px;
}
.report-table .report-td-name {
	width: 300px;
}
</style>