<template>
	<div class="account-tile" @click="showAccountView">
		<p style="font-weight: 500;">{{accountobj.name}}</p>
		<p style="font-size: 13px;">${{numdisplay(accountobj.lastbal)}}</p>
		<p v-if="accountobj.nToReconcile > 0">
			<span style="font-size: 11px">{{accountobj.nToReconcile}} Unreconciled Transactions</span>
		</p>
		<div v-if="accountobj.psfeedinfo.lastfetchtime.length > 0" style="font-size: 11px">
			<p :class="{clash : accountobj.psfeedinfo.feedbalance !== accountobj.lastbal}">
				${{numdisplay(accountobj.psfeedinfo.feedbalance)}} at {{accountobj.psfeedinfo.lastfetchtime}}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "AccountTile",
	props: {
		accountobj: Object,
	},
	data: function() {
		return {

		}
	},
	computed: {

	},
	methods: {
		showAccountView() {
			this.$store.commit('setActiveAccount', this.accountobj.id);
			this.$router.push('/account/'+this.accountobj.id);
		},
	},
	mounted() {

	},
	watch: {

	}
}
</script>

<style scoped>
	.account-tile {
		width: 300px;
		padding: 10px 20px;
		border-radius: 5px;
		border: 1px solid #CCC;
		margin-bottom: 20px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
	}
	.account-tile p {
		line-height: 1.5;
	}
	.account-tile:hover {
		background-color: #CCC;
	}
	.clash {
		color: #EA315C;
	}
</style>