<template>
	<div style="display: flex; padding: 10px 0 0px; font-weight: bold;">
		<div class="memo"><b-form-input type="text" v-model="mylineitem.nshr" @blur="checkPosNegOnSell"></b-form-input></div>
		<div class="amount"><b-form-input type="number" v-model="mylineitem.shrprice"></b-form-input></div>
		<div class="memo" style="display: flex">
			<b-form-select class="midwidth" v-model="mylineitem.buylotid" v-if="itxtype === 'Sell'" style="margin-right: 10px;">
				<option value="0">Unassigned</option>
				<option v-for="bl in buylotsremain" :key="bl.lotid" :value="bl.lotid">{{bl.nremain}} remaining of {{bl.lottotalshr}} (${{numdisplay(bl.shrprice)}}, {{bl.date}}</option>
			</b-form-select>
			<span v-if="itxtype === 'Sell'" class="kbtn kbtn-inline" @click="prepareMatchView">Match</span>
		</div>
		<div style="display: flex; align-items: center; padding: 5px 10px;">
			<span @click="$emit('deleteline')" style="cursor: pointer;"><b-icon class="overlay-close-icon" style="color: #444;" icon="x"></b-icon></span>
		</div>

		<div class="overlay" v-if="showAssignModal === true">
			<div class="trans-editor" style="width: 800px; margin: 50px auto;">
				<div class="topbar" style="">
					<p class="topbartext">
						Match Sale Lots
					</p>
					<span style="cursor: pointer;" @click="showAssignModal = false">
						<b-icon class="overlay-close-icon" icon="x"></b-icon>
					</span>
				</div>
				<div style="padding: 20px;">
					<div style="display: flex; justify-content: space-between; padding: 0 0 10px;">
						<div>Total Sold: {{totalLineToMatch}}</div>
						<div>Assigned So Far: {{totAssigned}}</div>
						<div>Remain: {{nRemainToMatch}}</div>
						<div>Date: {{datein}}</div>
					</div>
					<div style="max-height: 60vh; overflow-y: auto">
						<LotAssignLine v-for="bl in buylotsremain" :key="bl.lotid"
								:bl="bl" v-on:nassigned="assignAmount(bl.lotid, $event)"
								:dounassign="doUnassign" :doassign="doAssign"
						></LotAssignLine>
					</div>
					<div style="padding-top: 20px;">
						<span class="kbtn kbtn-inline" style="margin-right: 10px;" @click="applyLines">Apply</span>
						<span class="kbtn kbtn-inline backbtn" style="margin-right: 10px;" @click="unassignAll">Unassign</span>
						<span class="kbtn kbtn-inline backbtn" @click="assignAll">Assign</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import LotAssignLine from "@/components/LotAssignLine";
export default {
	name: "TransactionEditLineShareTrade",
	components: {
		LotAssignLine
	},
	props: {
		lineitem: Object,
		itxtype: String,
		datein: String,
	},
	data: function() {
		return {
			mylineitem: {
				nshr: 0,
				shrprice: 0,
				secticker: '',
				buylotid: 0,
			},
			showAssignModal: false,
			assignedArray: [],
			doUnassign: false,
			doAssign: true,
		}
	},
	computed: {
		nRemainToMatch() {
			return this.totalLineToMatch - this.totAssigned;
		},
		activeAccount() {
			return this.$store.getters.activeAccount;
		},
		buylotsremain() {
			return this.activeAccount.remainingLots[this.mylineitem.secticker];
		},
		totalLineToMatch() {
			if(this.mylineitem.nshr === 0) return 0;
			return this.mylineitem.nshr * -1;
		},
		totAssigned() {
			var out = 0;
			for(var ix=0; ix<this.assignedArray.length; ix++) {
				out = out + parseFloat(this.assignedArray[ix].nassigned);
			}
			return out;
		}
	},
	methods: {
		applyLines() {
			if(this.nRemainToMatch < 1 && this.nRemainToMatch > -1) {
				this.$emit('updatelines', this.assignedArray);
				this.assignedArray = [];
				this.showAssignModal = false;
			}
			else {
				alert("You have some shares still to match (reminder not zero)");
			}
		},
		unassignAll() {
			this.doUnassign = true;
			setTimeout(function() {
				this.doUnassign = false;
			}.bind(this), 10);
			this.assignedArray = [];
		},
		assignAll() {
			this.assignedArray = [];
			this.doAssign = true;
			setTimeout(function() {
				this.doAssign = false;
			}.bind(this), 10);

		},
		assignAmount(lotid, num) {
			var indfound = false;
			for(var ind=0; ind<this.assignedArray.length; ind++) {
				var myob = this.assignedArray[ind];
				if(myob.lotid === lotid) {
					indfound = ind;
				}
			}
			var newitem = {
				lotid: lotid,
				nassigned: num,
				secticker: this.mylineitem.secticker,
				shrprice: this.mylineitem.shrprice,
			};
			if(indfound === false) {
				this.assignedArray.push(newitem);
			}
			else {
				Vue.set(this.assignedArray, indfound, newitem);
			}
		},
		prepareMatchView() {
			if(this.mylineitem.nshr === 0 || this.shrprice === 0 || this.secticker === "") {
				alert("Please enter the number of shares, price and ticker first");
				return false;
			}
			this.showAssignModal = true;
		},
		prepareItem() {
			this.mylineitem = this.lineitem;
		},
		checkPosNegOnSell() {
			if(this.mylineitem.nshr > 0 && this.itxtype === 'Sell') {
				this.mylineitem.nshr = this.mylineitem.nshr * -1;
			}
		}
	},
	mounted() {
		this.prepareItem();
	},
	watch: {
		lineitem() {
			this.prepareItem();
		},
		mylineitem() {
			this.$emit('update', this.mylineitem);
		},
	}
}
</script>

<style scoped>
.category {
	width: 400px;
}
.memo {
	width: 400px;
	margin-right: 10px;
}
.amount {
	width: 100px;
	margin-right: 10px;
}
.amount input {
	text-align: right;
}
.investflds {
	width: 90px;
	margin-right: 10px;
}
.topbar {
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #888;
	color: #FFF;
	font-weight: bold;
	background: #0078c8;
}
</style>