<template>
	<div class="trow" style="display: flex;" @click="editRule(myrule.ai)">
		<div class='midxw'><span v-if="myrule.strmatch">{{myrule.strmatch}}</span></div>
		<div class='midxw'><span v-if="myrule.setpayeeid">{{payeename}}</span></div>
		<div class='midxw'><span v-if="myrule.setsubcatid">{{subcatname}}</span></div>
	</div>
</template>

<script>
export default {
	name: "RuleTableItem",
	components: {},
	props: {
		myrule: Object,
	},
	data: function () {
		return {}
	},
	computed: {
		payeename() {
			var p = this.$store.state.payees.find(item => item.id === this.myrule.setpayeeid);
			return p.name;
		},
		subcatname() {
			var p = this.$store.state.subcats.find(item => item.id === this.myrule.setsubcatid);
			return p.fullname;
		},
	},
	methods: {
		editRule(rid) {
			this.$emit('editrule', rid);
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>