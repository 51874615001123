<template>
	<div class="main-container">
		<NavBar></NavBar>

		<div class="internal-container" style="">
			<div class="rules-hold">

				<div class='general-table'>

					<div class='headrow'>
						<div class='midxw' style="display: flex; align-items: center;">
							<span>Match String</span>
						</div>
						<div class='midxw' style="display: flex; align-items: center;">
							<span>Payee</span>
						</div>
						<div class='midxw' style="display: flex; justify-content: space-between; padding-right: 20px;">
							<span style="display: flex; align-items: center;">Category</span>
							<span class="kbtn kbtn-inline kbtn-in-nav" @click="newRule">New</span>
						</div>
					</div>

					<div v-if="$store.state.sesscheckcomplete">
						<RuleTableItem v-for="t in rules" :key="t.ai" :myrule="t" v-on:editrule="editRule($event)"></RuleTableItem>
					</div>

				</div>
			</div>

			<div class="overlay" v-if="txEditShown">
				<RuleEdit :ruleid="activeRuleId"
						v-on:closemodal="txEditShown = false"
						v-on:updaterules="finishEditRule($event)"
				></RuleEdit>
			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import RuleTableItem from "@/components/RuleTableItem";
import RuleEdit from "@/components/RuleEdit";
import NavBar from "@/components/NavBar";

export default {
	name: "RulesView",
	components: {
		NavBar,
		RuleEdit,
		RuleTableItem,
	},
	data: function () {
		return {
			rules: [],
			txEditShown: false,
			activeRuleId: 0,
		}
	},
	computed: {

	},
	methods: {
		editRule(rid) {
			this.activeRuleId = rid;
			this.txEditShown = true;
		},
		newRule() {
			this.activeRuleId = 0;
			this.txEditShown = true;
		},
		finishEditRule(ruleitems) {
			this.txEditShown = false;
			this.activeRuleId = 0;
			this.rules = ruleitems;
		},
		getRulesList() {
			let self = this;
			let form = {getter: 'rules'};
			axios.post(
				"/post/get-rules.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.rules) {
					self.rules = ret.rules;
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	mounted() {
		this.getRulesList();
	},
}
</script>

<style scoped>
.rules-hold {
	padding: 0 20px 20px;
	width: 100%;
}
.headrow {
	display: flex;
}
.midl {
	width: 300px;
	margin-right: 10px;
}
</style>