<template>
	<div id="leftnav">

		<div v-for="acc in accountsNotHidden"
			:key="acc.id" @click="showAccountView(acc.id)"
			class="left-nav-item" :class="{activeAccountClass : $store.state.activeAccountId === acc.id}"
		>
			<div class="accname-hold">{{acc.name}} <span v-if="acc.nToReconcile > 0" class="needsrec">{{acc.nToReconcile}}</span></div>
			<div :class="{rednum : acc.lastbal < 0}">${{numdisplay(acc.lastbal)}}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LeftAccountList",
	data: function () {
		return {

		}
	},
	computed: {
		accountsNotHidden() {
			return this.$store.state.accounts.filter(item => item.hidden !== 1);
		},
	},
	methods: {
		showAccountView(accid) {
			this.$store.commit('setActiveAccount', accid);
			this.$router.push("/account/"+accid);
		},
	},
}

</script>

<style scoped>
#leftnav {
	width: 280px;
	min-width: 280px;
	background: #FFF;
	border-right: 1px solid #D5D5D5;
	top: 50px;
	position: fixed;
	padding: 10px 0;
	height: 100%;
}
.left-nav-item {
	padding: 7px 5%;
	width: 100%;
	display: flex;
	font-size: 11px;
	justify-content: space-between;
	cursor: pointer;
}
.activeAccountClass, .left-nav-item:hover {
	background-color: #DDD;
}
.accname-hold {
	display: flex;
	align-items: center;
}
.rednum {
	color: #EA315C;
}
.needsrec {
	width: 14px;
	height: 14px;
	border-radius: 7px;
	display: flex;
	color: #FFF;
	background-color: #0078c8;
	margin-left: 7px;
	text-align: center;
	font-size: 10px;
	align-items: center;
	justify-content: center;
}
</style>