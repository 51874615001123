<template>
	<div class="chart-holder" :class="addclasses" :style="'height: '+this.holderHeight+'px;'">
		<div style="height: 40px; padding: 0 20px; border-bottom: 1px solid #CCC; background-color: #FAFAFA; display: flex; justify-content: space-between;">
			<p style="line-height: 1; color: #444; font-weight: 500; font-size: 13px; align-items: center; display: flex;">{{charttitle}}</p>
			<slot name="headerRight"></slot>
		</div>
		<div :style="'height: '+this.chartHeight+'px;'" style="padding: 0px 0 0px; justify-content:center; position: relative; display: flex; width: 100%;">
			<slot name="default"></slot>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ChartHolderDash',
	components: {},
	data() {
		return {

		}
	},
	computed: {
		chartHeight() {
			if(this.height) return this.height;
			else return 400;
		},
		holderHeight() {
			if(this.height) return this.height + 60;
			else return 486;
		}
	},
	methods: {

	},
	watch: {},
	props: {
		charttitle: String,
		source: String,
		addclasses: String,
		height: Number
	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>

</style>
