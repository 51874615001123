<template>
	<div class="trans-editor">
		<div class="topbar" style="">
			<p class="topbartext">
				<span v-if="isCopy">COPY </span>
				<span v-if="!isCopy && transob.id === 0">NEW </span>
				Record Details
			</p>
			<span style="cursor: pointer;" @click="$emit('closemodal')"><b-icon class="overlay-close-icon" icon="x"></b-icon></span>
		</div>
		<div id="txformhold" class="popform" style="display: block;">
			<div id="txForm">
				<div class="tx-form-top-section">
					<b-form-select class="midwidth" v-model="transob.accid" :options="accountsDropdownList"></b-form-select>
					<b-input-group class="datechoose">
						<b-form-input
								v-model="transob.datestr"
								type="text"
								placeholder="YYYY-MM-DD"
								autocomplete="off"
						></b-form-input>
						<b-input-group-append>
							<b-form-datepicker
									v-model="transob.datestr"
									button-only
									right
									locale="en-US"
							></b-form-datepicker>
						</b-input-group-append>
					</b-input-group>

					<Autocompleter :searchlist="$store.state.payees" :incomingval="transob.payeeId"
								idfldname="id" textfldname="name" v-on:update="updatePayeeObject($event)"
					></Autocompleter>

					<b-form-select class="midwidth" style="width: 120px;" v-model="transob.reconciled">
						<option value="0">Uncleared</option>
						<option value="1">Reconciled</option>
						<option value="2">Projected</option>
					</b-form-select>

					<b-form-checkbox
							id="estcheckbox"
							v-model="transob.isEstimate"
							name="estcheckbox" size="lg"
					>
						<span style="font-size: 13px; margin-left: 5px;">Estimate</span>
					</b-form-checkbox>
				</div>

				<div style="display: flex; padding: 30px 0 10px; margin-bottom: 0px; font-weight: bold;">
					<div class="category"><span style="padding: 0 0px;">Category</span></div>
					<div class="memo">Memo</div>
					<div class="amount">Amount</div>
					<div class="investflds">Ticker</div>
					<div class="investflds">FrkCred</div>
				</div>

				<div style="margin-bottom: 10px;">
					<TransactionEditLine v-for="(li, index) in transob.lineItems" :key="index"
							:lineitem="li" v-on:update="li = $event"
							v-on:deleteline="removeLine(index)"
					></TransactionEditLine>
				</div>

				<div style="padding: 5px 0; margin-bottom: 10px;">
					<span @click="newlineitem" style="font-size: 12px; color: #888; cursor:pointer;">New Line Item </span>
				</div>

				<div style="display: flex; justify-content: space-between; padding: 10px 0 10px">
					<div>
						<span class="primary-button" @click="saveAndRec();">Save & Reconcile</span>
						<span class="primary-button" @click="saveTX();">Save</span>
						<span class="primary-button button-delete" @click="deleteTX();">Delete</span>
						<span class="primary-button button-grey" @click="cloneTX();">Copy To New</span>
					</div>
					<div style="font-weight: bold; font-size: 16px;">
						Total: ${{numdisplay(transTotalAmount)}}
					</div>
				</div>

			</div>


		</div>
	</div>
</template>

<script>
import axios from "axios";
import TransactionEditLine from "@/components/TransactionEditLine";
import Autocompleter from "@/components/Autocompleter";

export default {
	name: "TransactionEdit",
	components: {Autocompleter, TransactionEditLine},
	props: {
		transid: Number,
	},
	data: function() {
		return {
			transob: {
				txid: 0,
				accid: 0,
				datestr: "2021-05-31",
				memo: '',
				payeeId: 0,
				payeeObj: {
					id: 0,
					name: '',
				},
				lineItems: [],
				isEstimate: false,
				reconciled: 0,
			},
			setAsReconciled: false,
			isCopy: false,
			hasNoInitialMatch: null,
		}
	},
	computed: {
		transTotalAmount() {
			var amt = 0;
			for(var li=0; li<this.transob.lineItems.length; li++) {
				amt = amt + parseFloat(this.transob.lineItems[li].amount);
			}
			return amt;
		},
		accountsDropdownList() {
			let accs = [];
			for(var i=0; i<this.$store.state.accounts.length; i++) {
				var myacc = this.$store.state.accounts[i];
				accs.push({value: myacc.id, text: myacc.name});
			}
			return accs;
		},
	},
	methods: {
		cloneTX() {
			this.isCopy = true;
			this.transob.txid = 0;
			for(var i=0; i<this.transob.lineItems.length; i++) {
				this.transob.lineItems[i].itemId = 0;
			}
		},
		deleteTX() {
			let self = this;
			let form = {txid: this.transid, accid: this.transob.accid};
			axios.post(
				"/post/delete-transaction.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.transactions) {
					self.$emit('updatetransactions', ret.transactions);
				}
				if(ret.accounts) {
					self.$store.commit('updateAccountsLists', ret.accounts);
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		saveAndRec() {
			this.setAsReconciled = true;
			this.saveTX();
		},
		saveTX() {
			let self = this;
			if(this.setAsReconciled === true) {
				this.transob.reconciled = 1;
			}
			let form = {tx: this.transob};
			axios.post(
				"/post/save-transaction.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.payees) {
					self.$store.commit('updatePayeesList', ret.payees);
				}
				if(ret.transactions) {
					if(ret.savedTransaction && self.hasNoInitialMatch === true) {
						self.$store.commit('addToRecentRecTx', ret.savedTransaction);
					}
					self.$emit('updatetransactions', ret.transactions);
				}
				if(ret.accounts) {
					self.$store.commit('updateAccountsLists', ret.accounts);
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		updatePayeeObject(ob) {
			this.transob.payeeId = ob.id;
			this.transob.payeeObj = ob;
		},
		removeLine(indexid) {
			this.transob.lineItems.splice(indexid, 1);
		},
		newlineitem() {
			this.transob.lineItems.push({
				itemId: 0,
				amount: 0,
				memo: '',
				subCatId: 0,
				subcatObj: {id: 0, fullname: ''},
				secticker: '',
				frkcrd: 0,
			});
		},
		getTransaction(txid) {
			if(txid > 0) {
				let self = this;
				let form = {txid: txid};
				axios.post(
					"/post/get-transaction-detail.php",
					JSON.stringify({
						data: form,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.transaction) {
						self.transob = ret.transaction;
						if(self.transob.payeeId === 0 || self.transob.payeeId === null) {
							self.hasNoInitialMatch = true;
						}
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
			else {
				this.transob.accid = this.$store.state.activeAccountId;
				let today = new Date();
				var d = String(today.getDate()).padStart(2, '0');
				var m = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var y = today.getFullYear();
				this.transob.datestr = y+"-"+m+"-"+d;
				this.newlineitem();
			}
		},
	},
	mounted() {
		this.getTransaction(this.transid);
	},
	watch: {
		transid() {
			this.getTransaction(this.transid);
		},
	}
}
</script>

<style scoped>
.trans-editor {
	width: 1200px;
}
#txformhold {
	padding: 20px;
}
.tx-form-top-section {
	display: flex;
	justify-content: space-between;
}
.midwidth {
	width: 200px;
	margin-right: 10px;
}
.datechoose {
	width: 300px;
	margin-right: 10px;
}
.autocompleter {
	width: 350px;
}
.category {
	width: 360px;
}
.memo {
	width: 400px;
	margin-right: 10px;
}
.amount {
	width: 150px;
	margin-right: 10px;
}
.investflds {
	width: 90px;
	margin-right: 10px;
}
</style>