<template>
	<div>

		<div class="register-table-before"></div>
		<div class='register-table'>
			<div class="register-above" v-if="activeAccountId > 0">
				<div class="searchbox-hold">
					<input type="text" v-model="searchtext" class="searchbox" placeholder="Search"/>
				</div>
				<div v-if="activeAccount.psfeedinfo.lastfetchtime.length > 0">
					<p class="aboveregtext" :class="{clash : activeAccount.psfeedinfo.feedbalance !== activeAccount.lastbal}">
						<span v-if="$store.state.pocketsmithsyncing === false">Last Pocketsmith sync: {{activeAccount.psfeedinfo.lastfetchtime}}. PS Balance ${{numdisplay(activeAccount.psfeedinfo.feedbalance)}}</span>
						<span v-if="$store.state.pocketsmithsyncing === true">Syncing...</span>
					</p>
				</div>
				<div>
					<span style="cursor:pointer; padding-right: 10px; margin-left: 10px; color: #fff; font-size: 12px;" @click="addNewTx">Add Manual Transaction</span>
				</div>
			</div>

			<div class='headrow'>
				<div class='mids'>Date</div>
				<div class='midl'>Payee</div>
				<div class='midw'>Category</div>
				<div class='midl'>Memo</div>
				<div class='rt txwid'>Payment</div>
				<div class='rt txwid'>Deposit</div>
				<div class='rt txwid'>Balance</div>
				<div class="rt recwid">R</div>
			</div>

			<div class="table-rows">
				<div v-for="t in shownTransactions" :key="t.txid" class='trow'
					:class="{unrec : t.reconciled === 2, reconciled : t.reconciled === 1}"
					@click="editTransaction(t.txid)"
				>
					<div class='mids'>{{t.datestr}}</div>
					<div class='midl'>
						<span v-if="t.reconciled === 2 && t.payeeId && t.subcatId"
							class="kbtn acceptbtn" @click.stop="acceptTxSuggestion(t.txid)">M</span>
						<span v-if="t.payeeObj">{{t.payeeObj.name}}</span>
					</div>
					<div class='midw'><span v-if="t.subcatObj">{{t.subcatObj.fullname}}</span></div>
					<div class='midl'><span>{{t.memoRollup}}</span></div>
					<div class='rt txwid'><span v-if="t.totalAmount < 0">{{numdisplay(t.totalAmount)}}</span></div>
					<div class='rt txwid'><span v-if="t.totalAmount >= 0">{{numdisplay(t.totalAmount)}}</span></div>
					<div class='rt txwid'>{{numdisplay(t.runningBalance)}}</div>
					<div class='rt recwid'>
						<span v-if="t.reconciled === 1">R</span>
					</div>
				</div>
			</div>
		</div>
		<div v-if="transactions.length > shownTransactions.length" @click="itemsShown = 0" class="show-more-div" style="width: 1120px;">
			<span class="kbtn">Show All</span>
		</div>

		<div class="overlay" v-if="txEditShown">
			<TransactionEdit :transid="activeTransId"
						v-on:closemodal="txEditShown = false"
						v-on:updatetransactions="finishEditTransaction($event)"
			></TransactionEdit>
		</div>
	</div>
</template>

<script>
import TransactionEdit from "@/components/TransactionEdit";
import axios from "axios";

export default {
	name: "RegisterTable",
	components: {
		TransactionEdit,
	},
	props: {

	},
	data: function () {
		return {
			transactions: [],
			itemsShown: 200,
			txEditShown: false,
			activeTransId: 0,
			searchtext: '',
		}
	},
	computed: {
		activeAccountId() {
			return this.$store.state.activeAccountId;
		},
		activeAccount() {
			return this.$store.getters.activeAccount;
		},
		pocketsmithsyncing() {
			return this.$store.state.pocketsmithsyncing;
		},
		shownTransactions() {
			if (this.searchtext.length > 2) {
				var src = this.searchtext.toLowerCase();
				return this.transactions.filter(item =>
					(item.payeeObj && item.payeeObj.name.toLowerCase().includes(src)) ||
					(item.subcatObj && item.subcatObj.fullname.toLowerCase().includes(src)) ||
					item.memoRollup.toLowerCase().includes(src)
				);
			}
			if (this.itemsShown === 0) return this.transactions; //return all if limit not set
			else return this.transactions.slice(0, this.itemsShown); //just the first n(200) transactions
		},
	},
	methods: {
		addNewTx() {
			this.activeTransId = 0;
			this.txEditShown = true;
		},
		finishEditTransaction(transitems) {
			this.txEditShown = false;
			this.activeTransId = 0;
			this.transactions = transitems;
		},
		editTransaction(txid) {
			this.activeTransId = txid;
			this.txEditShown = true;
		},
		getTransactions() {
			let self = this;
			let form = {accid: this.$store.state.activeAccountId};
			axios.post(
				"/post/get-transactions.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.payees) {
					self.$store.commit('updatePayeesList', ret.payees);
				}
				if (ret.accounts) {
					self.$store.commit('updateAccountsLists', ret.accounts);
				}
				if (ret.transactions) {
					self.transactions = ret.transactions;
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		acceptTxSuggestion(txid) {
			let self = this
			let form = {txid: txid, accid: this.activeAccountId}
			axios.post(
				"/post/mark-transaction-reconciled.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				let ret = response.data //response.data is returned info
				if(ret.transactions) {
					self.finishEditTransaction(ret.transactions)
				}
				if(ret.accounts) {
					self.$store.commit('updateAccountsLists', ret.accounts);
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		activeAccountId() {
			this.itemsShown = 200; //reset this on change of account
			this.getTransactions();
		},
		pocketsmithsyncing() {
			if (this.pocketsmithsyncing === false) {
				this.getTransactions();
			}
		},
	},
	mounted() {
		if (this.activeAccountId > 0) {
			this.getTransactions();
		}
	}
}
</script>

<style scoped>
.acceptbtn {
	cursor: pointer;
	min-width: unset;
	padding: 0 8px;
	width: 30px;
	min-height: unset;
	display: inline-flex;
	margin-right: 5px;
}

</style>