<template>
	<div>
		<input style="margin-right: 10px;" type="checkbox" v-model="ischecked" />
		<input type="number" :max="bl.lottotalshr" v-model="nassignedthisline">
		{{bl.nremain}} remaining of {{bl.lottotalshr}} (${{numdisplay(bl.shrprice)}}, {{bl.date}})
	</div>
</template>

<script>
export default {
	name: "LotAssignLine",
	components: {},
	props: {
		bl: Object,
		dounassign: Boolean,
		doassign: Boolean,
	},
	data: function () {
		return {
			nassignedthisline: 0,
			ischecked: false,
		}
	},
	computed: {},
	methods: {},
	watch: {
		nassignedthisline() {
			this.$emit('nassigned', parseFloat(this.nassignedthisline));
		},
		dounassign() {
			this.ischecked = false;
		},
		doassign() {
			this.ischecked = true;
		},
		ischecked() {
			if(this.ischecked) {
				this.nassignedthisline = this.bl.nremain;
			}
			else {
				this.nassignedthisline = 0;
			}
		}
	},
	mounted() {

	}
}
</script>

<style scoped>

</style>