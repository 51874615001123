<template>
	<div style="width: 100%;" v-if="$store.state.user.id > 0">
		<NavBar></NavBar>
		<div class="internal-container">

			<div class="dashboard-container">
				<div class="subnav-holder">
					<span class="subtab" @click="tabview='summary'" :class="{subtabactive : tabview === 'summary'}">
						<span>Summary</span>
					</span>
					<span class="subtab" @click="tabview='chart'" :class="{subtabactive : tabview === 'chart'}">
						<span>Chart</span>
					</span>
				</div>

				<div v-if="tabview === 'summary' && investingdata.accdata" class="account-tile-hold" style="margin-top: 50px;">
					<div style="padding: 20px;">
						<div style="display: flex; justify-content: space-between; padding-bottom: 10px;">
							<p class="heading">Non-Super Investing Summary</p>
							<b-input-group class="datechoose" style="width: 300px;">
								<b-form-input
										v-model="enddate"
										type="text"
										placeholder="YYYY-MM-DD"
										autocomplete="off"
								></b-form-input>
								<b-input-group-append>
									<b-form-datepicker
											v-model="enddate"
											button-only
											right
											locale="en-US"
									></b-form-datepicker>
								</b-input-group-append>
							</b-input-group>
						</div>

						<div class="shrtbl">
							<div class="shrhd ">
								<div class="theadrow shrtoprow">
									<div class="mwid"></div>
									<div class="mwid borderRight"></div>

									<div v-for="acc in investingdata.accdata" :key="acc.id" class="account-width borderRight bold" style="justify-content: center; padding:8px 0px;">
										{{acc.name}}
									</div>
								</div>
								<div class="theadrow">
									<div class="mwid lt bold">Security</div>
									<div class="mwid borderRight bold">Curr Price</div>

									<div v-for="acc in investingdata.accdata" :key="acc.id" class="account-width borderRight">
										<div class="mwid bold">n Shares</div>
										<div class="mwid bold">Value</div>
										<div class="mwid bold">UCG</div>
										<div class="mwid bold">Avg PP</div>
									</div>
								</div>
							</div>
							<div class="shrbd">
								<div class="twowid">
									<div v-for="sec in showtickers" :key="sec" style="display: flex;" class="borderBot">
										<div class="mwid lt bold">{{sec}}</div>
										<div class="mwid borderRight">{{datedSecurityPriceByTicker(sec)}}</div>
									</div>
									<div style="display: flex; border-top: 1px solid #888;">
										<div class="mwid lt bold">Total</div>
										<div class="mwid borderRight"></div>
									</div>
								</div>

								<div v-for="acc in investingdata.accdata" :key="acc.id" class="account-info">
									<div v-for="sec in showtickers" :key="sec" style="display: flex;" class="borderBot">
										<div class="mwid">
											<span v-if="acc.secholds[sec]">{{numdisplayround(acc.secholds[sec].nshr)}}</span>
											<span v-else>-</span>
										</div>
										<div class="mwid">
											<span v-if="acc.secholds[sec]">{{numdisplayround(acc.secholds[sec].totalval)}}</span>
											<span v-else>-</span>
										</div>
										<div class="mwid">
											<span v-if="acc.secholds[sec]">{{numdisplayround(acc.secholds[sec].unrealisedCapGains)}}</span>
											<span v-else>-</span>
										</div>
										<div class="mwid borderRight">
											<span v-if="acc.secholds[sec]">{{acc.secholds[sec].avgCostPriceOfRemain}}</span>
											<span v-else>-</span>
										</div>
									</div>
									<div style="display:flex; border-top: 1px solid #888;">
										<div class="mwid"></div>
										<div class="mwid">
											<span class="bold">{{numdisplayround(acc.totalInvestVal)}}</span>
										</div>
										<div class="mwid">
											<span class="bold">{{numdisplayround(acc.totalUCG)}}</span>
										</div>
										<div class="mwid borderRight"></div>
									</div>
								</div>

							</div>
						</div>

						<!-- Total table -->
						<div style="display: flex;">

							<div class="shrtbl" style="width: 595px; border-right: 1px solid #888;">
								<div class="shrhd">
									<div class="theadrow shrtoprow">
										<div class="mwid"></div>
										<div class="mwid borderRight"></div>

										<div class="account-width bold" style="width: 425px; justify-content: center; padding:8px 0px;">
											Total Taxable Accounts
										</div>
									</div>
									<div class="theadrow">
										<div class="mwid lt bold">Security</div>
										<div class="mwid borderRight bold">Curr Price</div>

										<div class="account-width">
											<div class="mwid bold">n Shares</div>
											<div class="mwid bold">Value</div>
											<div class="mwid bold">UCG</div>
											<div class="mwid bold">Avg PP</div>
											<div class="mwid bold">Pct</div>
										</div>
									</div>
								</div>
								<div class="shrbd">
									<div class="twowid">
										<div style="display: flex;" class="borderBot">
											<div class="mwid lt bold">Cash</div>
											<div class="mwid borderRight"></div>
										</div>
										<div v-for="sec in showtickers" :key="sec" style="display: flex;" class="borderBot">
											<div class="mwid lt bol bold">{{sec}}</div>
											<div class="mwid borderRight">{{datedSecurityPriceByTicker(sec)}}</div>
										</div>
										<div style="display: flex; border-top: 1px solid #888;">
											<div class="mwid lt bol bold">Total</div>
											<div class="mwid borderRight"></div>
										</div>
									</div>

									<div class="">
										<div style="display: flex;" class="borderBot">
											<div class="mwid"></div>
											<div class="mwid">
												<span>{{numdisplayround(investingdata.cashballess100)}}</span>
											</div>
											<div class="mwid"></div>
											<div class="mwid"></div>
											<div class="mwid">
												<span>{{investingdata.cashpct}}%</span>
											</div>
										</div>
										<div v-for="sec in showtickers" :key="sec" style="display: flex;" class="borderBot">
											<div class="mwid">
												<span v-if="investingdata.totals[sec].nshr">{{numdisplayround(investingdata.totals[sec].nshr)}}</span>
												<span v-else>-</span>
											</div>
											<div class="mwid">
												<span v-if="investingdata.totals[sec]">{{numdisplayround(investingdata.totals[sec].totalval)}}</span>
												<span v-else>-</span>
											</div>
											<div class="mwid">
												<span v-if="investingdata.totals[sec]">{{numdisplayround(investingdata.totals[sec].unrealisedCapGains)}}</span>
												<span v-else>-</span>
											</div>
											<div class="mwid">
												<span v-if="investingdata.totals[sec]">{{investingdata.totals[sec].avgCostPriceOfRemain}}</span>
												<span v-else>-</span>
											</div>
											<div class="mwid">
												<span v-if="investingdata.totals[sec]">{{investingdata.totals[sec].pct}}%</span>
												<span v-else>-</span>
											</div>
										</div>
										<div style="display:flex; border-top: 1px solid #888;" class="borderBot">
											<div class="mwid"></div>
											<div class="mwid bold">
												<span>{{numdisplayround(investingdata.grandtotalval)}}</span>
											</div>
											<div class="mwid bold">
												<span>{{numdisplayround(investingdata.grandtotalucg)}}</span>
											</div>
											<div class="mwid"></div>
										</div>
									</div>

								</div>
							</div>

							<div style="width: 400px; height: 241px; margin-left: 40px; background-color: #FFF; border: 1px solid #888;">
								<div v-if="lookupdone" class="chart-container" style="height: 240px; width: 100%; padding-left: 6%;">
									<apexchart width="100%" height="100%" type="donut" :options="aansoptions" :series="aansseries"></apexchart>
								</div>
							</div>

						</div>

					</div>
				</div>

				<div v-if="tabview === 'chart' && nwseries.length >0" class="account-tile-hold" style="position: absolute; width: 100%;">
					<div style="margin-top: 70px; padding: 10px 50px; width: 100%; position: relative;">
						<ChartHolderDash :charttitle="'Running Investing Balance'" :height="600" :addclasses="'chartholderdash'">
							<div v-if="lookupdone" class="chart-container" style="width: 100%; ">
								<apexchart width="99%" height="100%" type="area" :options="nwoptions" :series="nwseries"></apexchart>
							</div>
							<ChartLoaderInsert v-if="lookupdone === false"></ChartLoaderInsert>
						</ChartHolderDash>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
export default {
	name: "Investing",
	components: {
		ChartLoaderInsert,
		ChartHolderDash,
		NavBar
	},
	props: {},
	data: function () {
		return {
			tabview: 'summary',
			lookupdone: false,
			investingdata: [],
			showtickers: ['AFI','VAS','VGS','ARG','VAF','PMGOLD'],
			enddate: '',
			aansoptions: {
				chart: {
					id: 'assetallocns',
					type: 'donut',
					toolbar: {
						show: false,
					},
				},
				labels: [],
				tooltip: {
					y: {
						formatter: function(val) {
							return "$" + Math.round(val).toLocaleString('en-us');
						},
					}
				},
			},
			aansseries: [],

			nwoptions: {
				chart: {
					id: 'networth',
					toolbar: {
						show: true,
					},
				},
				xaxis: {
					categories: [],
					labels: {
						show: true,
					},
					axisTicks: {
						show: true,
					},
					type: 'datetime',
				},
				toolbar: {
					autoSelected: 'zoom',
				},
				yaxis: {
					decimalsInFloat: 3,
					labels: {
						formatter: function(val) {
							return "$" + val.toLocaleString('en-us');
						},
					},
				},
				tooltip: {
					x: {
						format: 'dd MMM yyyy'
					}
				},
				stroke: {
					width: 2.5,
					curve: 'straight',
					colors: ['#0078c8'],
				},
				fill: {
					shadeIntensity: 1,
					colors: ['#0078c8'],
					opacity: 0.1,
					gradient: {
						opacityFrom: 0.7,
						opacityTo: 0.1,
					},
				},
				dataLabels: {
					enabled: false,
				},
				markers: {
					size: 0,
				},
			},
			nwseries: [],
			isWorking: false,
		}
	},
	computed: {
		wsjcount() {
			return this.$store.state.wsjcheckcount;
		},
	},
	methods: {
		doSummaryLookups() {
			if(this.isWorking === false) {
				this.isWorking = true;
				let self = this;
				let thisenddate = this.enddate;
				if (thisenddate === '') {
					thisenddate = this.dateobToYmd(new Date());
				}
				let form = {
					type: 'nonsuper',
					enddate: thisenddate,
				};
				axios.post(
					"/post/get-investing-summary.php",
					JSON.stringify({
						data: form,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.investing) {
						self.investingdata = ret.investing;
						self.aansseries = ret.allocchartnonsuper.series;
						self.aansoptions.labels = ret.allocchartnonsuper.labels;
					}
					self.lookupdone = true;
					self.isWorking = false;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		doChartLookup() {
			this.lookupdone = false;
			let self = this;
			let form = {
				getter: 'dashnetworth',
			};
			axios.post(
				"/post/get-investing-runbal.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				self.nwoptions.xaxis.categories = ret.nwchart.xaxis_categories;
				self.nwseries = ret.nwchart.series;
				self.lookupdone = true;
			}).catch(function (error) {
				console.log(error);
			});
		},
		datedSecurityPriceByTicker(ticker) {
			let sec = this.investingdata.secpricesondate.find(item => item.ticker === ticker)	;
			if(sec !== undefined) {
				return sec.shrprice;
			}
			return false;
		},
	},
	watch: {
		tabview() {
			if(this.tabview === 'chart') {
				this.doChartLookup();
			}
		},
		enddate() {
			this.doSummaryLookups();
		},
		wsjcount() {
			if(this.tabview === 'summary') {
				this.doSummaryLookups();
			}
		},
	},
	mounted() {
		this.doSummaryLookups();
		document.title = "Fintrac - Investing";
		this.enddate = this.dateobToYmd(new Date());
	}
}
</script>

<style scoped>
.shrtbl {
	border: 1px solid #888;
	border-right: none;
	margin-bottom: 40px;
	background-color: #FFF;
}
.shrhd, .shrbd {
	display: block
}
.shrbd {
	display: flex;
}
.account-width {
	width: 340px;
	display: flex;
	flex-shrink: 0;
	flex-grow: 0;
}
.theadrow {
	display: flex;
	border-bottom: 1px solid #888;
}
.account-info {
	display: block;
	width: 340px;
}
.twowid {
	width: 170px;
	flex-shrink: 0;
	flex-grow: 0;
}
.mwid {
	width: 85px;
	flex-shrink: 0;
	flex-grow: 0;
	text-align: right;
	padding: 5px 5px 3px;
}
.shrtoprow {
	color: #FFF;
	background-color: #0078c8;
}
.lt {
	text-align: left;
}
.borderRight {
	border-right: 1px solid #888;
}
.borderBot {
	border-bottom: 1px solid #EEE;
}
</style>