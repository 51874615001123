<template>
	<div class="trans-editor">
		<div class="topbar" style="">
			<p class="topbartext">
				<span v-if="isCopy">COPY </span>
				<span v-if="!isCopy && myrule.ai === 0">NEW </span>
				Record Details
			</p>
			<span style="cursor: pointer;" @click="$emit('closemodal')"><b-icon class="overlay-close-icon" icon="x"></b-icon></span>
		</div>
		<div id="txformhold" class="popform" style="display: block;">
			<div id="txForm">
				<div style="display: flex; justify-content: space-between; padding-bottom: 10px;">
					<div class="strmatchinput">String to match</div>
					<div class="" style="width: 190px;">Payee</div>
					<div class="" style="width: 190px;">Category</div>
				</div>
				<div class="tx-form-top-section">
					<b-form-input class="strmatchinput" type="text" v-model="myrule.strmatch"></b-form-input>
					<Autocompleter :searchlist="$store.state.payees" :incomingval="myrule.setpayeeid"
						idfldname="id" textfldname="name" v-on:update="updatePayeeObject($event)"
					></Autocompleter>
					<Autocompleter :searchlist="$store.state.subcats" :incomingval="myrule.setsubcatid"
						idfldname="id" textfldname="fullname" v-on:update="updateSubcatObject($event)"
					></Autocompleter>
				</div>

				<div style="display: flex; justify-content: space-between; padding: 30px 0 10px">
					<div style="display: flex; justify-content: space-between; width: 100%; align-items: center;">
						<div>
							<span class="primary-button" @click="saveTX();">Save</span>
							<span class="primary-button button-delete" @click="deleteTX();">Delete</span>
							<span class="primary-button button-grey" @click="cloneTX();">Copy To New</span>
						</div>

						<div v-if="!isCopy && myrule.ai === 0">
							<b-select v-model="recentTxSelected" style="width: 200px;">
								<option value=""></option>
								<option v-for="tx in $store.state.recentReconciledTransactions" :value="tx" :key="tx.id">{{tx.lineItems[0].memo}}</option>
							</b-select>
							<span class="primary-button" style="margin-left: 5px;" @click="useRecent">Use</span>
						</div>
					</div>
				</div>

			</div>


		</div>
	</div>
</template>

<script>
import axios from "axios";
import Autocompleter from "@/components/Autocompleter";

export default {
	name: "RuleEdit",
	components: {Autocompleter},
	props: {
		ruleid: Number,
	},
	data: function() {
		return {
			myrule: {
				ai: 0,
				accid: 0,
				strmatch: '',
				setpayeeid: 0,
				payeeObj: {
					id: 0,
					name: '',
				},
				subcatObj: {id: 0, fullname: ''},
				setsubcatid: 0,
			},
			isCopy: false,
			recentTxSelected: null,
		}
	},
	computed: {

	},
	methods: {
		useRecent() {
			this.myrule.strmatch = this.recentTxSelected.lineItems[0].memo;
			this.myrule.setpayeeid = this.recentTxSelected.payeeId;
			this.myrule.setsubcatid = this.recentTxSelected.lineItems[0].subCatId;
		},
		cloneTX() {
			this.isCopy = true;
			this.myrule.ai = 0;
		},
		deleteTX() {
			let self = this;
			let form = {rid: this.ruleid};
			axios.post(
				"/post/delete-rule.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.rules) {
					self.$emit("updaterules", ret.rules);
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		saveTX() {
			let self = this;
			let form = {rule: this.myrule};
			axios.post(
				"/post/save-rule.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.rules) {
					self.$emit("updaterules", ret.rules);
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		updatePayeeObject(ob) {
			this.myrule.setpayeeid = ob.id;
			this.myrule.payeeObj = ob;
		},
		updateSubcatObject(ob) {
			this.myrule.setsubcatid = ob.id;
			this.myrule.subcatObj = ob;
		},
		getRule(rid) {
			if(rid > 0) {
				let self = this;
				let form = {rid: rid};
				axios.post(
					"/post/get-rule-detail.php",
					JSON.stringify({
						data: form,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.rule) {
						self.myrule = ret.rule;
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	mounted() {
		this.getRule(this.ruleid);
	},
	watch: {
		transid() {
			this.getRule(this.ruleid);
		},
	}
}
</script>

<style scoped>
.trans-editor {
	width: 900px;
}
#txformhold {
	padding: 20px;
}
.tx-form-top-section {
	display: flex;
	justify-content: space-between;
}
.strmatchinput {
	width: 400px;
	margin-right: 10px;
}
</style>